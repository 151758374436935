<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="transactions && transactions.length > 0">
      <div class="row m-0 justify-content-center">
        <div class="col-auto my-3">
          <DonationsFilter :theFilter="theFilter" @updateDate="updateDate" @updateDonationType="updateDonationType" @updatePrice="updatePrice" @updateDonor="updateDonor" :selectedItems="selectedItems" @printSelected="printSelected" @printRange="printRange" @downloadSelected="downloadSelected" @printAll="printAll" @downloadAll="downloadAll" @downloadRange="downloadRange" />
        </div>
      </div>
      <div class="row mx-0  my-3 justify-content-center align-items-center" v-if="isLoading">
        <div class="col-auto ps-0">
          Loading More Donations
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <div class="col-12 px-0 px-md-3">
          <!--DESKTOP-->
          <div class="row font13 green-text-dark d-none d-md-flex">
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                DONOR
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                TRANSACTION REF
              </div>
            </div>
            <div class="col bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                DATE
              </div>
            </div>
            <div class="col-md-1 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                AMOUNT
              </div>
            </div>
            <div class="col bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                PAYMENT TYPE
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                TRANSACTION STATUS
              </div>
            </div>
            <div class="col-md-2 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
          <!--END DESKTOP-->
          <!--MOBILE-->
          <div class="row font13 green-text-dark d-md-none bold">
            <div class="col-3 text-center py-3 px-1">
              Receipt No.
            </div>
            <div class="col-3 text-center py-3 px-1">
              Date
            </div>
            <div class="col-3 text-center py-3 px-1">
              Amount
            </div>
            <div class="col-3 text-center py-3 px-1">
              Info
            </div>
          </div>
          <!--END MOBILE-->
        </div>
      </div>
      <!-- END TABLE HEADING -->
      <div class="row m-0" v-if="filteredDonations.length < 1">
        <div class="col-12 p-3">
          There are no donations for the current filter, please try a different filter.
        </div>
      </div>
      <PastDonationItem v-for="(donation, key) in filteredDonations.slice(start, finish)" :key="key" :item="donation" :thekey="key" @showDetails="showDetails(key)" @downloadReceipt="downloadReceipt(donation)" @printReceipt="printReceipt(donation.transactionID)" @addSelected="addSelected(donation.transactionID)" @removeSelected="removeSelected(donation.transactionID)" />
      <transition name="slideup">
      <DonationPopup v-if="currentItem" :item="currentItem" :theKey="currentKey" :totalItems="filteredDonations.length" @close="closeDetails" @downloadReceipt="downloadReceipt(currentItem)" @emailReceipt="emailReceipt(currentItem.transactionID)" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-5" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12 p-0" v-else>
      <div class="row mx-0  my-3 justify-content-center align-items-center" v-if="isLoading">
        <div class="col-auto ps-0">
          Loading Donations
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="row mx-0 my-3 justify-content-center align-items-center" v-else>
        <div class="col-12 text-center mb-3">
          You don't have any past donations, try donating now.
        </div>
        <div class="col-12 text-center">
          <Button color="red" btnText="Donate Now" icon="arrow" @buttonClicked="goTo('donate')">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    PastDonationItem: defineAsyncComponent(() => import('@/views/PastDonationItem.vue')),
    DonationsFilter: defineAsyncComponent(() => import('@/views/DonationsFilter.vue')),
    DonationPopup: defineAsyncComponent(() => import('@/views/DonationPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'PastDonations',
  props: ['transactionSearch', 'isLoading', 'theFilter'],
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      donor: this.theFilter,
      itemsPerPage: 10,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      selectedItems: []
    }
  },
  watch: {
    transactionSearch () {
      this.currentPage = 0
    }
  },
  computed: {
    ...mapGetters(['user', 'userTransactions', 'organisationTransactions', 'transactions']),
    totalPages () {
      return Math.ceil(this.filteredDonations.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    ukTransactions () {
      return this.transactions.filter(transaction => {
        if (transaction.transactionStatus.transactionStatusID !== 4 && transaction.transactionStatus.transactionStatusID !== 5 && transaction.transactionStatus.transactionStatusID !== 12 && transaction.transactionStatus.transactionStatusID !== 14) {
          return false
        }
        return true
      })
    },
    filteredDonations () {
      const search = this.transactionSearch.toLowerCase().trim()
      if (this.isUK) {
        return this.ukTransactions.filter(transaction => {
          if (this.donationType) {
            let ret = false
            transaction.donationList.forEach(item => {
              if (this.donationType.includes(item.donationType.donationTypeID)) {
                ret = true
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (this.donor) {
            let ret = false
            this.donor.forEach((donor) => {
              if (donor.type === 'org') {
                if (transaction.organisationID && parseInt(donor.id) === transaction.organisationID) {
                  ret = true
                }
              } else if (donor.type === 'don') {
                if (!transaction.organisationID && !transaction.donorGroupID && parseInt(donor.id) === transaction.userID) {
                  ret = true
                }
              } else if (donor.type === 'gro') {
                if (transaction.donorGroupID && parseInt(donor.id) === transaction.donorGroupID) {
                  ret = true
                }
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (this.startDate && this.endDate && (transaction.transactionDate <= this.startDate || transaction.transactionDate >= this.endDate)) {
            return false
          }
          if (this.priceRange) {
            let ret = false
            this.priceRange.forEach((price) => {
              var res = price.split('-')
              for (var i = 0; i < res.length; i++) {
                res[i] = +res[i]
              }
              if (res[1] !== 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0] && transaction.estimatedValue < res[1]) {
                ret = true
              } else if (res[1] === 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0]) {
                ret = true
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (search) {
            let ret = false
            transaction.donationList.forEach(item => {
              if (item.project.description.toLowerCase().indexOf(search) > -1) {
                ret = true
              }
            })
            if (transaction.transactionReference.toLowerCase().indexOf(search) > -1) {
              ret = true
            }
            if (ret === false) {
              return false
            }
          }
          return true
        })
      } else {
        return this.transactions.filter(transaction => {
          if (this.donationType) {
            let ret = false
            transaction.donationList.forEach(item => {
              if (this.donationType.includes(item.donationType.donationTypeID)) {
                ret = true
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (this.donor) {
            let ret = false
            this.donor.forEach((donor) => {
              if (donor.type === 'org') {
                if (transaction.organisationID && parseInt(donor.id) === transaction.organisationID) {
                  ret = true
                }
              } else if (donor.type === 'don') {
                if (!transaction.organisationID && !transaction.donorGroupID && parseInt(donor.id) === transaction.userID) {
                  ret = true
                }
              } else if (donor.type === 'gro') {
                if (transaction.donorGroupID && parseInt(donor.id) === transaction.donorGroupID) {
                  ret = true
                }
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (this.startDate && this.endDate && (transaction.transactionDate <= this.startDate || transaction.transactionDate >= this.endDate)) {
            return false
          }
          if (this.priceRange) {
            let ret = false
            this.priceRange.forEach((price) => {
              var res = price.split('-')
              for (var i = 0; i < res.length; i++) {
                res[i] = +res[i]
              }
              if (res[1] !== 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0] && transaction.estimatedValue < res[1]) {
                ret = true
              } else if (res[1] === 1001 && transaction.estimatedValue && transaction.estimatedValue >= res[0]) {
                ret = true
              }
            })
            if (ret === false) {
              return false
            }
          }
          if (search) {
            let ret = false
            transaction.donationList.forEach(item => {
              if (item.project.description.toLowerCase().indexOf(search) > -1) {
                ret = true
              }
            })
            if (transaction.transactionReference.toLowerCase().indexOf(search) > -1) {
              ret = true
            }
            if (ret === false) {
              return false
            }
          }
          return true
        })
      }
    }
  },
  methods: {
    ...mapActions(['downloadTransactionReceipt', 'emailTransactionReceipt', 'printTransactionReceipt', 'downloadSummaryAll', 'printSummaryAll', 'downloadMultipleTransactionReceipt']),
    goToPage (val) {
      this.currentPage = val - 1
      this.$emit('goToDonations')
    },
    updateDate (val) {
      if (val) {
        const handler = {
          get (target, property) {
            return target[property]
          }
        }
        const proxyVal = new Proxy(val, handler)
        this.startDate = proxyVal.startDate
        this.endDate = proxyVal.endDate
      } else {
        this.startDate = null
        this.endDate = null
      }
      this.currentPage = 0
    },
    updatePrice (val) {
      if (val === null) {
        this.priceRange = null
      } else {
        this.priceRange = val
      }
      this.currentPage = 0
    },
    updateDonor (val) {
      if (val === null) {
        this.donor = null
      } else {
        this.donor = []
        val.forEach(item => {
          var theDonor = {
            type: '',
            id: null
          }
          theDonor.type = item.substring(0, 3)
          theDonor.id = item.slice(3)
          this.donor.push(theDonor)
        })
      }
      this.currentPage = 0
    },
    updateDonationType (val) {
      if (val === null) {
        this.donationType = null
      } else {
        this.donationType = val
      }
      this.currentPage = 0
    },
    goTo (val) {
      window.scrollTo(0, 0)
      this.$router.push('/' + val)
    },
    showDetails (val) {
      this.currentKey = this.start + val
      this.currentItem = this.filteredDonations[this.currentKey]
    },
    async printReceipt (val) {
      await this.printTransactionReceipt(val)
    },
    async printSelected () {
      var toPrintUser = {
        transactionIDs: [],
        userID: this.user.userID
      }
      var orgs = []
      this.selectedItems.forEach(item => {
        if (item.organisationID) {
          if (orgs.includes(item.organisationID)) {
            orgs[item.organisationID].transactionIDs.push(item.transactionID)
            orgs[item.organisationID].userID = this.user.userID
            orgs[item.organisationID].organisationID = item.organisationID
          } else {
            orgs.push(item.organisationID)
            orgs[item.organisationID].transactionIDs.push(item.transactionID)
            orgs[item.organisationID].userID = this.user.userID
            orgs[item.organisationID].organisationID = item.organisationID
          }
        } else {
          toPrintUser.transactionIDs.push(item.transactionID)
        }
      })
      if (orgs.length > 0) {
        orgs.forEach(async org => {
          await this.downloadMultipleTransactionReceipt(org)
        })
      }
      await this.downloadMultipleTransactionReceipt(toPrintUser)
    },
    printAll () {
      this.printSummaryAll()
    },
    printRange (val) {
      this.transactions.forEach(item => {
        if (item.transactionDate >= val.startDate && item.transactionDate <= val.endDate) {
          this.printReceipt(item.transactionID)
        }
      })
    },
    async downloadReceipt (val) {
      await this.downloadTransactionReceipt(val)
    },
    async emailReceipt (val) {
      await this.emailTransactionReceipt(val)
    },
    downloadAll () {
      this.downloadSummaryAll()
    },
    async downloadSelected () {
      var toPrintUser = {
        transactionIDs: [],
        userID: this.user.userID
      }
      var orgs = []
      this.selectedItems.forEach(item => {
        if (item.organisationID) {
          if (orgs.includes(item.organisationID)) {
            orgs[item.organisationID].transactionIDs.push(item.transactionID)
            orgs[item.organisationID].userID = this.user.userID
            orgs[item.organisationID].organisationID = item.organisationID
          } else {
            orgs.push(item.organisationID)
            orgs[item.organisationID].transactionIDs.push(item.transactionID)
            orgs[item.organisationID].userID = this.user.userID
            orgs[item.organisationID].organisationID = item.organisationID
          }
        } else {
          toPrintUser.transactionIDs.push(item.transactionID)
        }
      })
      if (orgs.length > 0) {
        orgs.forEach(async org => {
          await this.downloadMultipleTransactionReceipt(org)
        })
      }
      await this.downloadMultipleTransactionReceipt(toPrintUser)
    },
    downloadRange (val) {
      this.transactions.forEach(item => {
        if (item.transactionDate >= val.startDate && item.transactionDate <= val.endDate) {
          this.downloadReceipt(item.transactionID)
        }
      })
    },
    goForward (val) {
      this.currentItem = this.filteredDonations[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.filteredDonations[val - 1]
      this.currentKey = val - 1
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
    },
    addSelected (val) {
      this.selectedItems.push(val)
    },
    removeSelected (val) {
      var idx = this.selectedItems.indexOf(val)
      if (idx !== -1) this.selectedItems.splice(idx, 1)
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
